.services-box {
  padding: 40px 20px;
  text-align: center;
  background-color: #f9f9f9;
  padding: 50px 25px;
  border-radius: 5px;
}
.services-box:hover {
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
  
}
.icon1 {
  background-color: #fff3f0;
  position: relative;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-bottom: 30px;
  color: #fe4c1c;
}
.services-box:hover .icon1{
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.services-box:hover .icon2{
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.services-box:hover .icon3{
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.icon2 {
  background-color: #e8e0ff;
  position: relative;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-bottom: 30px;
  color: #290390;
}
.icon3 {
  background-color: #ebfffe;
  position: relative;
  border-radius: 50%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-bottom: 30px;
  color: #1fa299;
}
.services-box h4 {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 700;
  color: #212529;
}
.services-box p {
  color: #4b5280;
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.8;
}

.learn-more-btn {
  margin-top: 10px;
  display: inline-block;
  -moz-transition: 5s;
  transition: 0.5s;
  padding: 10px 20px;
  background-color: #fe4c1c;
  color: #fff;
}

.learn-more-btn:hover {
  -moz-transition: 5s;
  transition: 0.5s;
  border-radius: 20px;
  background-color: #080e32;
  color: #fff;
}

.header-section {
  padding: 80px 0px 80px 0px;
}
.header-section h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}
.header-section h1 {
  font-size: 38px;
  font-weight: 700;
  text-align: center;
}
.header-section p {
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  margin-top: 12px;
  color: #4b5280;
  text-align: center;
}

.abouthome {
  text-align: left;
}
.abouthome h6 {
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  padding-top: 80px;
}
.abouthome h4 {
  font-size: 38px;
  font-weight: 700;
}
.abouthome p {
  color: #4b5280;
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.8;
}
.readmore {
  text-align: center;
  display: inline-block;
  -moz-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  border: none;
  padding: 12px 26px;
  position: relative;
  z-index: 1;
  color: #fff;
  background-color: #fe4c1c;
  font-size: 17px;
  font-weight: 700;
}
.readmore:hover {
  color: #fff;
  background-color: #080e32;
  -moz-transition: 0.5s;
  transition: 0.5s;
  border-radius: 30px;
}

/* serviceshome */
.serviceshome{
    /* padding: 80px 0px 80px 0px; */
    background-color: #f9f9f9;
}
.serviceshome h6{
    display: block;
    margin-bottom: 12px;
    color: #fe4c1c;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    padding-top: 80px;
}
.serviceshome h4{
    font-size: 38px;
    font-weight: 700;
    text-align: center;
}
.serviceshome p{
    max-width: 600px;
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8;
    margin-top: 12px;
    color: #4b5280;
    text-align: center;
}
.services-box-home{
    background-color: #fff;
    padding: 50px 35px;
    border-radius: 5px;
    transform: .5s;
    text-align: left;
}
.services-box-home h4{
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: 700;
    color: #212529;
    text-align: left;
}
.services-box-home p{
    color: #4b5280;
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 1.8;
    font-weight: 400;
    text-align: left;
}
.services-box-home:hover{
  border-radius: 0;
  background-color: #fff;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 1s;
}
.services-box-home:hover .icon1{ 
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.services-box-home:hover .icon2{ 
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.services-box-home:hover .icon3{ 
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
/* Header 4 */
.home-test{
  padding: 100px 0px 80px 0px;
  text-align: left;
}
.home-test h6{
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
}
.home-test h4{
  font-size: 40px;
    font-weight: 700;
}
.home-test p{
  color: #4b5280;
  font-size: 16px;
  margin-bottom: 15px;
  line-height: 1.8;
  font-weight: 400;
}
.img-bg-test{
  background-image: url(../../assets/Images/test.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

/* Header 5 team */
.hometeamheader{
  padding: 10px 0px 10px 0px;
  text-align: left;
}

.hometeamheader h6{
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
}
.hometeamheader h4{
  font-size: 40px;
  font-weight: 700;
  text-align: left;
}
.hometeamheader p{
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  margin-top: 12px;
  color: #4b5280;
  text-align: left;
}

.our-team .pic{
  border-top: 5px solid #fe4c1c;
  border-bottom: 5px solid #fe4c1c;
  border-radius: 50% 50% 50% 0;
  overflow: hidden;
  transition: all 0.5s ease 0s;
}
.our-team:hover .pic{
  border-top-color: #080e32;
  border-bottom-color: #080e32;
  border-radius: 50% 0;
}
.our-team .pic img{
  width: 100%;
  height: auto;
  transition: all 0.5s ease 0s;
}
.our-team .team-content{ padding: 20px 0; }
.our-team .title{
  font-size: 18px;
  font-weight: 700;
  color: #080e32;
  text-transform: uppercase;
  margin: 0 0 5px 0;
}
.our-team .post{
  display: block;
  font-size: 16px;
  color: #4b5280;
  text-transform:capitalize;
  margin-bottom: 10px;
}
.our-team .social{
  list-style: none;
  padding: 0;
  margin: 0;
}
.our-team .social li{ display: inline-block; }
.our-team .social li a{
  display: block;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
  border: 1px solid #8f2d56;
  background: #fff;
  font-size: 17px;
  color: #8f2d56;
  text-align: center;
  transition: all 0.5s ease 0s;
}
.our-team:hover .social li a{
  background: #8f2d56;
  color: #fff;
}
@media only screen and (max-width: 990px){
  .our-team{ margin-bottom: 30px; }
}


/* header 6 price */
.homeprice{
  background-color: #f9f9f9;
}
.homeprice h6{
  padding-top: 100px;
  display: block;
  margin-bottom: 12px;
  color: #fe4c1c;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 700;
  text-align: center;
}
.homeprice h4{
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.homeprice-box{
  padding: 20px 0px 20px 0px;
  text-align: center;
}
.homeprice-box:hover .icon1{
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.homeprice-box:hover .icon2{
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.homeprice-box:hover .icon3{
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 15px 35px rgb(0 0 0 / 10%);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  transform: translateY(-10px);
  transition: 0.5s;
}
.homeprice p{
  max-width: 600px;
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.8;
  margin-top: 12px;
  color: #4b5280;
  text-align: center;
}
.homeprice-box{
background-color: #fff;
}

.homeprice-box .homeprice-header h3 {
    margin-bottom: 0;
    font-size: 24px;
    padding: 15px 10px 12px;
    font-weight: 700;
    text-align: center;
}
.homeprice-box .homeprice-list ul{
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  text-align: center;
}
.homeprice-box .homeprice-list{
  margin-bottom: 20px;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.homeprice-box .homeprice-list ul li{
  margin-bottom: 13px;
  color: #4b5280;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.homeprice-box .homeprice-price {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
}
.homeprice-box .homeprice-price span {
  display: block;
  color: #4b5280;
  margin-top: -3px;
  font-size: 18px;
  margin-top: -2px;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
