.navbar{
    height: 80px;
}

.nav-item{
    margin-left: 40px;
}
.nav-item .nav-link{
    font-size: 16px;
    font-weight: 700;
}
.nav-item .nav-link:hover{
  color: #000;
    transition: width .5s ease;
}
.nav-bg{
    background-color: #3c065e;
    color: #fff;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #fe4c1c;
}